import React from "react"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from "prop-types"

const AfterTreatmentImage = (props) => {
  const { mobileImage, desktopImage } = useStaticQuery(
    graphql`
      query {
        mobileImage: file(relativePath: { eq: "ht-at-mobile.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 640, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        desktopImage: file(relativePath: { eq: "hp-at-desktop.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  // Set ImageData.
  const sources = [
    mobileImage.childImageSharp.fluid,
    {
      ...desktopImage.childImageSharp.fluid,
      media: `(min-width: 641px)`,
    },
  ]

  return (
    <>
      <Img className="card__image-square" height="auto" fluid={sources} />
    </>
  )
}

export default AfterTreatmentImage

AfterTreatmentImage.defaultProps = {
  alt: 'This is where you have alt text @todo add me',
};

AfterTreatmentImage.propTypes = {
  alt: PropTypes.string.isRequired
}
