import React from "react"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from "prop-types"

const BravinHomeMobile = (props) => {
  const data  = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "bravin_neuro_banner_mobile.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 640, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  return (
    <>
      <Img className="homepage__skivolo-banner-mobile hide-for-large" width="100%" height="auto" fluid={data.file.childImageSharp.fluid} alt={props.alt}/>
    </>
  )
}

export default BravinHomeMobile

BravinHomeMobile.defaultProps = {
  alt: 'This is where you have alt text @todo add me',
};

BravinHomeMobile.propTypes = {
  alt: PropTypes.string.isRequired
}
