import React from "react"
import BackgroundImage from 'gatsby-background-image'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from "prop-types"

const BravinHome = (props) => {
  const { mobileImage, desktopImage } = useStaticQuery(
    graphql`
      query {
        mobileImage: file(relativePath: { eq: "bravin_neuro_banner.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 640, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        desktopImage: file(relativePath: { eq: "bravin_neuro_banner.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 641) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  // Set ImageData.
  const sources = [
    mobileImage.childImageSharp.fluid,
    {
      ...desktopImage.childImageSharp.fluid,
      media: `(min-width: 641px)`,
    },
  ]

  return (
    <BackgroundImage
      Tag="section"
      className="homepage__skivolo-bg"
      fluid={sources}
      backgroundColor={`#040e18`}
      style={{
        backgroundSize: `cover`,
        backgroundPosition: `top center`,
        backgroundRepeat: `no-repeat`
      }}
    >
    {props.children}
    </BackgroundImage>
  )
}

export default BravinHome

BravinHome.propTypes = {
  children: PropTypes.node.isRequired,
}
