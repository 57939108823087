import React, {useState} from "react"
import { Link } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import { graphql, useStaticQuery } from 'gatsby'

import cardPathOne from "../../../images/hp-cardpath-one.svg";
import cardPathTwo from "../../../images/hp-cardpath-two.svg";
import cardPathThree from "../../../images/hp-cardpath-three.svg";
import cardPathFour from "../../../images/hp-cardpath-four.svg";

import cardPathOneMob from "../../../images/hp-cardpath-mobile-one.svg";
import cardPathTwoMob from "../../../images/hp-cardpath-mobile-two.svg";
import cardPathThreeMob from "../../../images/hp-cardpath-mobile-three.svg";
import cardPathFourMob from "../../../images/hp-cardpath-mobile-four.svg";

import InTreatmentImage from "./intreatmentimage";
import NewlyDiagnosedImage from "./newlydiagnosedimage";
import AfterTreatmentImage from "./aftertreatmentimage";

const HeroPath = () => {
  const { mobileImage, desktopImage } = useStaticQuery(
    graphql`
      query {
        mobileImage: file(relativePath: { eq: "mobile_hero.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 640, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        desktopImage: file(relativePath: { eq: "GirlinGrass_Master_05CP-lhp.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 641) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  const [hasScrolled, setHasScrolled] = useState(false);

  // Set ImageData.
  const sources = [
    mobileImage.childImageSharp.fluid,
    {
      ...desktopImage.childImageSharp.fluid,
      media: `(min-width: 641px)`,
    },
  ]

  const hpScroll =(e)=>{
    if(!hasScrolled){
      window.scrollBy(0,400);
    }
    setHasScrolled(true);
    e.preventDefault();
  }

  return (
    <BackgroundImage
      Tag="section"
      className="homepage__hero"
      fluid={sources}
      backgroundColor={`#040e18`}
      style={{
        backgroundSize: `cover`,
        backgroundPosition: `top left`,
        backgroundRepeat: `no-repeat`
      }}
    >
    <div className="row row--inner align-center">
      <div className="columns small-12 large-8 text-center">
        <h1 className="section__heading uppercase">Neuroblastoma Support</h1>
        <h2 className="section__subheading h3">every step of the way</h2>
        <p className="section__p">From diagnosis to treatment and beyond, here you’ll find the information you need to help guide you and your child along their treatment journey.</p>
      </div>
    </div>
    <div className="row row--inner align-center">
      <div className="columns text-center">
        <button className="homepage__scroll-btn" onClick={(e)=> hpScroll(e)}>
          <span></span>
          <span></span>
          <span className="visually-hidden">scroll down to the next section</span>
          Scroll
        </button>
      </div>
    </div>
    <div className="homepage__card-path">

      {/* Card */}
      <div className="row row--inner">
        <div className="hp-card-path__container --path-one text-right medium-text-left">
          <img className="show-for-medium" aria-hidden="true" src={cardPathOne} width="50.5%" height="auto" alt="card-path-image-one"/>
          <img className="hide-for-medium" aria-hidden="true" src={cardPathOneMob} width="51.5%" height="auto" alt="card-path-image-one-mobile"/>
        </div>
        <div className="hp-card-path__wp-container">
          <div className="hp-card-path__waypoint --wp-one"></div>
        </div>
        <div className="columns small-12 shrink">
          <div className="card card--mw-700">
            <div className="row">
              <div className="columns small-12 medium-shrink">
                <NewlyDiagnosedImage/>
              </div>
              <div className="columns">
                <h3 className="section__heading section__heading--green h1"><em>Newly</em>  <span className="uppercase">Diagnosed</span></h3>
                <p>When your child is newly diagnosed with cancer, it’s normal to be concerned and have a lot of questions. Learn about your child’s diagnosis, your role as the caregiver, and what to expect with treatment.</p>
                <div className="row btn-group">
                  <div className="columns">
                    <Link to="/diagnosis/" className="btn btn--primary">Start your journey here</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Card */}


      {/* Card */}
      <div className="row row--inner align-right">
        <div className="hp-card-path__container --path-two text-right">
          <img className="show-for-medium" aria-hidden="true" src={cardPathTwo} width="80%" height="auto" alt="card-path-image-two"/>
          <img className="hide-for-medium" aria-hidden="true" src={cardPathTwoMob} width="50%" height="auto" alt="card-path-image-two-mobile"/>
        </div>
        <div className="hp-card-path__wp-container">
          <div className="hp-card-path__waypoint --wp-two"></div>
        </div>
        <div className="columns shrink">
          <div className="card card--mw-700">
            <div className="row">
              <div className="columns small-12 medium-shrink">
                <InTreatmentImage/>
              </div>
              <div className="columns small-12 medium-expand">
                <h3 className="section__heading section__heading--green h1"><em>In</em>  <span className="uppercase">Treatment</span></h3>
                <p>Whether your child has been diagnosed with low-, intermediate-, or high-risk neuroblastoma, here you’ll find information to help you navigate their treatment journey. </p>
                <div className="row btn-group">
                  <div className="columns small-12 medium-6">
                    <Link to="/treatment/non-high-risk-neuroblastoma/" className="btn btn--primary">Low- or intermediate- risk treatment</Link>
                  </div>
                  <div className="columns small-12 medium-6">
                    <Link to="/treatment/high-risk-neuroblastoma/" className="btn btn--primary">High-risk treatment</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Card */}

      {/* Card */}
      <div className="row row--inner">
        <div className="hp-card-path__container --path-three text-center medium-text-left">
          <img className="show-for-medium" aria-hidden="true" src={cardPathThree} width="18%" height="auto" alt="card-path-image-three"/>
          <img className="hide-for-medium" aria-hidden="true" src={cardPathThreeMob} width="80%" height="auto" alt="card-path-image-three-mobile"/>
        </div>
        <div className="hp-card-path__wp-container">
          <div className="hp-card-path__waypoint --wp-three"></div>
        </div>
        <div className="columns shrink">
          <div className="card card--mw-700">
            <div className="row">
              <div className="columns small-12 medium-shrink">
                <AfterTreatmentImage/>
              </div>
              <div className="columns">
                <h3 className="section__heading section__heading--green h1"><em>After</em> <span className="uppercase">Treatment</span></h3>
                <p>You and your child may have a lot of emotions about completing treatment and questions about what comes next. And that’s okay. Here you’ll find information to help you prepare for what comes after treatment has ended. </p>
                <div className="row btn-group">
                  <div className="columns">
                    <Link to="/after-treatment/whats-next/" className="btn btn--primary">Look beyond treatment</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hp-card-path__container --path-four text-center medium-text-left">
          <img className="show-for-medium" aria-hidden="true" src={cardPathFour} width="23%" height="auto" alt="card-path-image-four"/>
          <img className="hide-for-medium" aria-hidden="true" src={cardPathFourMob} width="44%" height="auto" alt="card-path-image-four-mobile"/>
        </div>
        <div className="hp-card-path__wp-container">
          <div className="hp-card-path__waypoint --wp-four-end"></div>
        </div>
      </div>
      {/* Card */}

    </div>
    {/* Card Path Container */}
    </BackgroundImage>
  )
}

export default HeroPath
